<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="用户手机号" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入用户手机号"></a-input>
					</a-form-item>
					
					<a-form-item label="用户昵称" name="nickname" class="ui-form__item">
						<a-input v-model:value="formState.nickname" placeholder="请输入用户昵称"></a-input>
					</a-form-item>

					<a-form-item label="内容类型" name="module" class="ui-form__item">
						<a-select v-model:value="formState.module" placeholder="请选择内容类型" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">评价邀请</a-select-option>
							<a-select-option :value="2">订单消息</a-select-option>
							<a-select-option :value="3">赞和评论</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="发送时间" class="ui-form__item">
						<a-range-picker v-model:value="time" showTime></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['operation_message_list_send']" type="primary"
							@click="onSend">消息配置</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div>
				<cTable ref="cTable" isPage :searchData="searchData" isRequest :requestFun="getMessageList" :columns="columns" :scroll="{x:1000}">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.extra.nickname || '-' }}</div>
								<div>用户手机号：{{ record.extra.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'type'">
							{{ ['-', '评价邀请', '订单消息', '赞和评论'][record.module] }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'isRead'">
							{{ record.isRead ? '已读' : '未读' }}
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>
	</div>
	<temp v-if="showModal" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import cTable from '@/components/cTable/index.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getMessageList } from '@/service/modules/operation.js';
	export default {
		components: {
			Icon,
			cTable,
			temp
		},
		data() {
			return {
				loading: false,
				time: [],
				formState: {},
				searchData: {},
				columns: [{
					title: '用户信息',
					key: 'userInfo',
				}, {
					title: '发送类型',
					key: 'type'
				}, {
					title: '消息发送时间',
					key: 'createTime',
				}, {
					title: '是否已读',
					key: 'isRead'
				}, {
					title: '发送内容',
					dataIndex: 'content'
				}],
				showModal: false,
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getMessageList: getMessageList,
			reset() {
				this.$refs.form.resetFields();
				this.time = [];
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].unix();
					this.searchData.endTime = this.time[1].unix();
				}
				this.getData();
			},
			getData() {
				this.$refs.cTable.refQuery();
			},
			onSend() {
				this.showModal = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>