<template>
	<div>
		<Header title="消息配置" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" @finish="onSubmit" :labelCol="{ span: 6 }" :wrapperCol="{span: 16}" style="margin-top: 10px;">
				<a-row>
					<a-col :span="12">
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">卖品核销提醒</h4>
							<a-form-item label="是否启用" name="snackVerification">
								<a-switch v-model:checked="modelRef.snackVerification" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
							
							<a-form-item label="核销后" name="snackVerificationDelay">
								<a-input-number v-model:value="modelRef.snackVerificationDelay" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span class="ui-min"> 分钟</span>
								<span style="margin-left: 5px">发送</span>
							</a-form-item>
						</div>
						<!-- <div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">订单生效配置</h4>
							<a-form-item label="是否启用" name="orderCreate">
								<a-switch v-model:checked="modelRef.orderCreate" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
							
							<a-form-item label="订单生效后" name="orderCreateDelay">
								<a-input-number v-model:value="modelRef.orderCreateDelay" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span class="ui-min"> 分钟</span>
								<span style="margin-left: 5px">发送</span>
							</a-form-item>
						</div> -->
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">订单发货提醒</h4>
							<a-form-item label="是否启用" name="orderSend">
								<a-switch v-model:checked="modelRef.orderSend" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
							
							<a-form-item label="完成发货后" name="orderSendDelay">
								<a-input-number v-model:value="modelRef.orderSendDelay" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span class="ui-min"> 分钟</span>
								<span style="margin-left: 5px">发送</span>
							</a-form-item>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">开影提醒</h4>
							<a-form-item label="是否启用" name="filmShow">
								<a-switch v-model:checked="modelRef.filmShow" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
							
							<a-form-item label="开影前" name="filmShowDelay">
								<a-input-number v-model:value="modelRef.filmShowDelay" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span class="ui-min"> 分钟</span>
								<span style="margin-left: 5px">发送</span>
							</a-form-item>
						</div>
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">评价邀请配置</h4>
							<a-form-item label="是否启用" name="comment">
								<a-switch v-model:checked="modelRef.comment" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
							
							<a-form-item label="影片放映结束后" name="commentDelay">
								<a-input-number v-model:value="modelRef.commentDelay" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<span class="ui-min"> 分钟</span>
								<span style="margin-left: 5px">发送</span>
							</a-form-item>
						</div>
					</a-col>
				</a-row>
				
				<a-row>
					<a-col :span="12">
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">收到点赞提醒</h4>
							<a-form-item label="是否启用" name="commentClick">
								<a-switch v-model:checked="modelRef.commentClick" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="ui-box">
							<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;">收到评论提醒</h4>
							<a-form-item label="是否启用" name="reply" extra="评论审核通过后提醒">
								<a-switch v-model:checked="modelRef.reply" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
						</div>
					</a-col>
				</a-row>
				
				<a-row>
					<a-col :offset="6" style="margin-top: 40px;">
						<a-button v-permission="['operation_message_configuration_submit']" type="primary"
							html-type="submit">提交</a-button>
							
						<a-button style="margin-left: 10px;" @click="onBack">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import {
		getDict,
		editConfig
	} from '@/service/modules/config.js';

	export default {
		components: {
			Header
		},
		data() {
			return {
				key: 'messagePushSetting',
				loading: false,
				cinemaAllList: [],
				modelRef: {},
				postData: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async onSubmit() {
				this.loading = true;
				this.postData.dataValue = JSON.parse(JSON.stringify(this.modelRef));
				let ret = await editConfig(this.postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功')
					this.onBack();
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getDict({
					key: this.key
				})
				this.loading = false;
				if (ret.code === 200) {
					this.postData = ret.data;
					this.modelRef = JSON.parse(ret.data.dataValue);
				}
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			}
		}
	}
</script>

<style scoped>
.ui-box {
	padding: 10px;
	margin-top: 10px;
	margin-right: 10px;
	border: solid 1px #eee;
	border-radius: 10px;
}
</style>